export const GET_CHANEL_SERVICE = 'STORE_GET_CHANEL_SERVICE';
export const ADD_CHANEL_SERVICE = 'STORE_ADD_CHANEL_SERVICE';
export const GET_OPERATION_HOURS = 'STORE_GET_OPERATION_HOURS';
export const OPERATION_HOURS_RESPONSE = 'OPERATION_HOURS_RESPONSE';
export const GET_OPERATION_HOURS_BATCH = 'GET_OPERATION_HOURS_BATCH';
export const GET_PAYMENT_MAPPING_HOURS = 'STORE_GET_PAYMENT_MAPPING_HOURS';
export const POST_PAYMENT_MAPPING_HOURS = 'STORE_POST_PAYMENT_MAPPING_HOURS';
export const PAYMENT_MAPPING_LOADING = 'STORE_PAYMENT_MAPPING_LOADING';
export const ADD_CHANEL_SERVICE_SINGLE_STORE = 'ADD_CHANEL_SERVICE_SINGLE_STORE';
export const ADD_STORE = 'STORE_ADD_STORE';
export const STORE_BASIC = 'STORE_STORE_BASIC';
export const ADD_PAYMENT_MAPPING_BATCH_ID = 'STORE_ ADD_PAYMENT_MAPPING_BATCH_ID';
export const STORE_PUBLISH = 'STORE_PUBLISH';
export const GET_COLLECTION_POINTS = 'GET_COLLECTION_POINTS';
export const POST_COLLECTION_POINTS = 'POST_COLLECTION_POINTS';
export const BATCH_COLLECTION_POINT = 'BATCH_COLLECTION_POINT';
export const GET_ADDITIONAL_CHARGES = 'GET_ADDITIONAL_CHARGES';
export const POST_ADDITIONAL_CHARGES = 'POST_ADDITIONAL_CHARGES';
export const GET_TRADING_HOURS = 'STORE_GET_TRADING_HOURS';
export const TRADING_HOURS_RESPONSE = 'TRADING_HOURS_RESPONSE';
export const GET_TRADING_HOURS_BATCH = 'GET_TRADING_HOURS_BATCH';
export const GET_DELIVERY_HOURS = 'STORE_GET_DELIVERY_HOURS';
export const DELIVERY_HOURS_RESPONSE = 'DELIVERY_HOURS_RESPONSE';
export const GET_DELIVERY_HOURS_BATCH = 'GET_DELIVERY_HOURS_BATCH';
export const GET_SCHEDULING_HOURS = 'GET_SCHEDULING_HOURS';
export const SCHEDULING_HOURS_RESPONSE = 'SCHEDULING_HOURS_RESPONSE';
export const GET_SCHEDULING_HOURS_BATCH = 'GET_SCHEDULING_HOURS_BATCH';
