import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import { Navbar, Dropdown, Button } from 'react-bootstrap';
// import i18n from '../../i18n';
import { deleteLocalStorage } from '../action/localStore';
import './header.scss';
// import tenantConfig from '../../config/tenantConfig.json';
import { revokeAuthentication } from '../../module/auth/Utils';
import logo from '../../assets/images/mainLogo.png';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="/"
    ref={ref}
    className="p-2"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

CustomToggle.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

CustomToggle.defaultProps = {
  onClick: () => null,
};

function Header() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const { t } = useTranslation();

  // const handleLangChange = (e) => {
  //   e.preventDefault();
  //   i18n.changeLanguage(e.target.value);
  // };

  const logout = () => {
    revokeAuthentication();
    dispatch(deleteLocalStorage());
  };

  // useEffect(() => {
  //   setAllowedLanguageOptions(tenantConfig.basic.allowedLanguages);
  // }, [tenantConfig]);

  const userInfo = useSelector((state) => state.oidc.user);

  return (
    <Navbar className="d-flex header-nav flex-md-row flex-sm-column align-items-center" expand="lg">
      <div className="d-flex flex-column align-items-center justify-content-center logo-wrapper">
        <Navbar.Brand href="/" className="p-0 m-0 ">
          <img
            src={logo}
            alt="logo"
            className="logo"
          />
        </Navbar.Brand>
      </div>

      <div className="d-flex mr-lg-5 header-user-info align-items-center justify-content-center">
        {/* <div>
          <select
            onChange={(e) => handleLangChange(e)}
            className="form-control"
            name="Globallanguage"
            id="GlobalLanguage"
          >
            {allowedLanguageOptions.map((language) => (
              <option key={language.key} value={language.value}>
                {language.label}
              </option>
            ))}
          </select>
        </div> */}
        <div className="text-sm mx-2 d-none d-lg-block pl-md-5">
          {userInfo?.profile?.['cognito:username'].includes('_') ? userInfo?.profile?.['cognito:username'].split('_')[1] : userInfo?.profile?.['cognito:username'] || 'Admin'}
        </div>
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            <span className="icons user-icon" />
          </Dropdown.Toggle>
          <Dropdown.Menu alignRight>
            <Dropdown.Item eventKey="1">
              {' '}
              <Button
                variant="link"
                className="selectAllBtn customDropdownBTN rounded"
                onClick={() => logout()}
              >
                {
                t('common.logOut')
                }
              </Button>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

      </div>
    </Navbar>
  );
}

export default Header;
