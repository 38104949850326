import {
  LOG_ERROR, FETCH_CONFIG_INFO, IS_LOADING, BATCH_JOB,
  CHANNEL_SERVICE_MENU_ITEM,
} from '../constants';

const initialState = {
  isLogError: '',
  config: null,
  batchservice: [],
  channelWiseMenuItems: null,
};

export default function CommonReducer(state = initialState, action) {
  switch (action.type) {
    case LOG_ERROR:
      return {
        ...state,
        isLogError: action.payLoad,
      };
    case FETCH_CONFIG_INFO:
      return {
        ...state,
        config: action.payLoad,
      };
    case IS_LOADING:
      return {
        ...state,
        isLoading: action.payLoad,
      };
    case BATCH_JOB:
      return {
        ...state,
        batchservice: action.payLoad,
      };
    case CHANNEL_SERVICE_MENU_ITEM:
      return {
        ...state,
        channelWiseMenuItems: action.payLoad,
      };
    default:
      return state;
  }
}
