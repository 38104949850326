export const GET_ITEMS_RECOMMENDATION = 'GET_ITEMS_RECOMMENDATION';
export const GET_BASKET_RECOMMENDATION = 'GET_BASKET_RECOMMENDATION';
export const GET_PRODUCT_MASTER_DETAILS = 'GET_PRODUCT_MASTER_DETAILS';
export const UPDATE_PRODUCT_SUCCESS_STATE = 'UPDATE_PRODUCT_SUCCESS_STATE';
export const GET_ALL_PRODUCT_RECOMMENDATIONS = 'GET_ALL_PRODUCT_RECOMMENDATIONS';
export const GET_IMAGE_SUCCESS = 'GET_IMAGE_SUCCESS';
export const IMAGE_BASE_URL = 'IMAGE_BASE_URL';
export const IMAGE_POST_URL = 'IMAGE_POST_URL';
export const PUBLISH_IMAGE_BASE_URL = 'PUBLISH_IMAGE_BASE_URL';
export const MENUPULL_ADDED = 'MENUPULL_ADDED';
export const MENUPULL_FETCHED = 'MENUPULL_FETCHED';
export const MONITOR_FETCHED = 'MONITOR_FETCHED';
export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';
export const GET_PRODUCT_DETAILS = 'GET_PRODUCT_DETAILS';
