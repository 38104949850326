import {
  GET_CHANEL_SERVICE,
  ADD_CHANEL_SERVICE,
  GET_OPERATION_HOURS,
  GET_TRADING_HOURS,
  GET_DELIVERY_HOURS,
  OPERATION_HOURS_RESPONSE,
  TRADING_HOURS_RESPONSE,
  DELIVERY_HOURS_RESPONSE,
  GET_OPERATION_HOURS_BATCH,
  GET_DELIVERY_HOURS_BATCH,
  GET_TRADING_HOURS_BATCH,
  GET_PAYMENT_MAPPING_HOURS,
  ADD_CHANEL_SERVICE_SINGLE_STORE,
  ADD_STORE,
  STORE_BASIC,
  POST_PAYMENT_MAPPING_HOURS,
  ADD_PAYMENT_MAPPING_BATCH_ID,
  STORE_PUBLISH,
  GET_COLLECTION_POINTS,
  POST_COLLECTION_POINTS,
  BATCH_COLLECTION_POINT,
  GET_ADDITIONAL_CHARGES,
  POST_ADDITIONAL_CHARGES,
  GET_SCHEDULING_HOURS,
  SCHEDULING_HOURS_RESPONSE,
  GET_SCHEDULING_HOURS_BATCH,
} from '../constants';

const initialState = {
  selectedStoreOperationHours: null,
  selectedStoreTradingHours: null,
  selectedStoreDeliveryHours: null,
  operationHoursResponse: null,
  tradingHoursResponse: null,
  deliveryHoursResponse: null,
  operationHoursBatchId: null,
  tradingHoursBatchId: null,
  deliveryHoursBatchId: null,
  singlePaymentMapOperationHours: [],
  postPaymentMapping: '',
  batchIdPaymentMapping: '',
  singleAddChanelService: '',
  chanelService: [],
  batchIdChanelService: '',
  storeBasic: [],
  isAdd: '',
  storePublish: null,
  collectionPoints: null,
  isCollectionPointAdd: null,
  batchCollectionPointsMapping: '',
  additionalCharges: null,
  additionalChargesUpdate: null,
  selectedOrderSchedulingHours: null,
  schedulingHoursResponse: null,
  schedulingHoursBatchId: null,
};

export default function RestaurantManagmentReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CHANEL_SERVICE:
      return {
        ...state,
        chanelService: action.payLoad,
      };
    case ADD_CHANEL_SERVICE:
      return {
        ...state,
        batchIdChanelService: action.payLoad,
        // isLoadingChanelServic: false,
      };
    case GET_OPERATION_HOURS:
      return {
        ...state,
        selectedStoreOperationHours: action.payLoad,
      };
    case GET_TRADING_HOURS:
      return {
        ...state,
        selectedStoreTradingHours: action.payLoad,
      };
    case GET_DELIVERY_HOURS:
      return {
        ...state,
        selectedStoreDeliveryHours: action.payLoad,
      };
    case OPERATION_HOURS_RESPONSE:
      return {
        ...state,
        operationHoursResponse: action.payLoad,
      };
    case TRADING_HOURS_RESPONSE:
      return {
        ...state,
        tradingHoursResponse: action.payLoad,
      };
    case DELIVERY_HOURS_RESPONSE:
      return {
        ...state,
        deliveryHoursResponse: action.payLoad,
      };
    case GET_OPERATION_HOURS_BATCH:
      return {
        ...state,
        operationHoursBatchId: action.payLoad,
      };
    case GET_TRADING_HOURS_BATCH:
      return {
        ...state,
        tradingHoursBatchId: action.payLoad,
      };
    case GET_DELIVERY_HOURS_BATCH:
      return {
        ...state,
        deliveryHoursBatchId: action.payLoad,
      };
    case GET_PAYMENT_MAPPING_HOURS:
      return {
        ...state,
        singlePaymentMapOperationHours: action.payLoad,
      };
    case POST_PAYMENT_MAPPING_HOURS:
      return {
        ...state,
        postPaymentMapping: action.payLoad,
      };
    case ADD_PAYMENT_MAPPING_BATCH_ID:
      return {
        ...state,
        batchIdPaymentMapping: action.payLoad,
      };
    case ADD_CHANEL_SERVICE_SINGLE_STORE:
      return {
        ...state,
        singleAddChanelService: action.payLoad,
      };
    case ADD_STORE:
      return {
        ...state,
        isAdd: action.payLoad,
      };
    case STORE_BASIC:
      return {
        ...state,
        storeBasic: action.payLoad,
      };
    case STORE_PUBLISH:
      return {
        ...state,
        storePublish: action.payLoad,
      };
    case GET_COLLECTION_POINTS:
      return {
        ...state,
        collectionPoints: action.payLoad,
      };
    case POST_COLLECTION_POINTS:
      return {
        ...state,
        isCollectionPointAdd: action.payLoad,
      };
    case BATCH_COLLECTION_POINT:
      return {
        ...state,
        batchCollectionPointsSuccess: action.payLoad,
      };
    case GET_ADDITIONAL_CHARGES:
      return {
        ...state,
        additionalCharges: action.payLoad,
      };
    case POST_ADDITIONAL_CHARGES:
      return {
        ...state,
        additionalChargesUpdate: action.payLoad,
      };
    case GET_SCHEDULING_HOURS:
      return {
        ...state,
        selectedOrderSchedulingHours: action.payLoad,
      };
    case SCHEDULING_HOURS_RESPONSE:
      return {
        ...state,
        schedulingHoursResponse: action.payLoad,
      };
    case GET_SCHEDULING_HOURS_BATCH:
      return {
        ...state,
        schedulingHoursBatchId: action.payLoad,
      };
    default:
      return state;
  }
}
