import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import { reducer as OidcReducer } from 'redux-oidc';
import storageSession from 'redux-persist/lib/storage/session';
import CommonReducer from '../common/reducers';
import LocalStoreReducer from '../common/reducers/localStore';
import RestaurantManagmentReducer from '../module/restaurantManagment/reducer/index';
import OloManagementReducer from '../module/oloManagement/reducers';
import ProductManagmentReducer from '../module/productManagement/reducer';
import KioskManagementReducer from '../module/kiosk/reducer';
import CouponManagmentReducer from '../module/coupon/reducer';
import PaymentConfigurationReducer from '../module/paymentConfiguration/reducer';
import ReportManagmentReducer from '../module/report/reducer';
import UserManagmentReducer from '../module/userManagement/reducer';
import CustomerManagementReducer from '../module/customerManagement/reducer';
import ConfigManagementReducer from '../module/configManagement/reducer';

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['LocalStoreReducer'], // which reducer want to store
};

const pReducer = combineReducers({
  LocalStoreReducer,
  CommonReducer,
  RestaurantManagmentReducer,
  OloManagementReducer,
  ProductManagmentReducer,
  KioskManagementReducer,
  oidc: OidcReducer,
  CouponManagmentReducer,
  PaymentConfigurationReducer,
  ReportManagmentReducer,
  UserManagmentReducer,
  CustomerManagementReducer,
  ConfigManagementReducer,
});

const rootReducer = persistReducer(persistConfig, pReducer);

export default rootReducer;
