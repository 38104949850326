import {
  GET_COMPANY_SETTINGS,
  POST_COMPANY_SETTINGS,
  GET_ADD_HOPE,
  POST_ADD_HOPE,
} from '../constants';

const initialState = {
  getCompanySettings: null,
  postCompanySettings: null,
  getAddHope: null,
  postAddHope: null,
};

export default function ConfigManagementReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COMPANY_SETTINGS:
      return {
        ...state,
        getCompanySettings: action.payLoad,
      };
    case POST_COMPANY_SETTINGS:
      return {
        ...state,
        postCompanySettings: action.payLoad,
      };
    case GET_ADD_HOPE:
      return {
        ...state,
        getAddHope: action.payLoad,
      };
    case POST_ADD_HOPE:
      return {
        ...state,
        postAddHope: action.payLoad,
      };
    default:
      return state;
  }
}
