import { createBrowserHistory } from 'history';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, Card } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FaAngleDown } from 'react-icons/fa';
// import custom from '../../config/sidebarConfig.json';
// import customOne from '../../config/sidebarConfigCopy.json';
import './Sidebar.scss';
import { gettingALLStore } from '../action/localStore';

const Sidebar = () => {
  const history = createBrowserHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const storeNames = useSelector((state) => state.LocalStoreReducer?.mappingStore?.storeName);
  // const sidebardata = process.env.REACT_APP_ENV !== 'development' ? custom : customOne;
  // const defaultKey = history.location.pathname.split('/')[
  //   history.location.pathname.split('/').length - 1
  // ];
  const authNavigations = useSelector((state) => state.LocalStoreReducer.authNavigations);

  useEffect(() => {
    !storeNames && dispatch(gettingALLStore());
  }, []);

  // const sidebardata = process.env.REACT_APP_ENV !== 'development' ? custom : customOne;
  const defaultKey = history.location.pathname.split('/')[history.location.pathname.split('/').length - 1];
  return (
    <>
      <Accordion
        as="ul"
        defaultActiveKey={defaultKey}
      >
        {authNavigations.map((data) => (data.subTitles.length > 0 ? (

          <Card className="font-sm rounded-0" key={data.id}>
            <Accordion.Toggle
              as={Card.Header}
              key={data.id}
              eventKey={data.subTitles.find((each) => each.url === defaultKey)?.url || data.id}
              className="d-flex justify-content-between"
            >
              <div className="link">{t(data.title)}</div>
              <div className="link">
                <span className="side-menu-arrow ml-4">
                  <FaAngleDown />
                </span>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse
              eventKey={data.subTitles.find((each) => each.url === defaultKey)?.url || data.id}
            >
              <Card.Body>
                {data.subTitles.map((sub) => (
                  <li key={sub.id} className="list-unstyled sub-menu position-relative">
                    <NavLink activeClassName="activeSideBarLink" to={sub.url ? sub.url : ''}>
                      {t(sub.title)}
                    </NavLink>
                  </li>
                ))}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ) : (
          <Card className="font-sm rounded-0" key={data.id}>
            <Card.Header>
              <NavLink className="link" to={data.url ? data.url : ''}>
                {t(data.title)}
              </NavLink>
            </Card.Header>
          </Card>
        )))}
      </Accordion>
    </>
  );
};

export default Sidebar;
