import axios from 'axios';
import { uniqBy } from 'lodash';
import {
  LOG_ERROR, IS_LOADING, BATCH_JOB, CHANNEL_SERVICE_MENU_ITEM,
} from '../constants';
import { isStatusCodeSuccess } from '../../utils';
import { getUserInfo } from '../../module/auth/Utils';
import i18n from '../../i18n';

export const getServiceCall = async (args) => {
  const baseURL = args.baseURL || process.env.REACT_APP_BASE_URL;
  const {
    url, method, data, requestBaseUrl, ContentType, noCache, loaderFlag, imageAuthorizationHeader,
  } = args;
  const responseType = 'json';
  const user = await getUserInfo();
  // to do 'x-correlation-request-id - different for every request
  // x-correlation-session-id should be same for all rquest type but different for every login
  // x-tenant-id devepend on tenant config

  let headers = {
    'content-type': ContentType || 'application/json',
    // 'x-correlation-request-id': 'v5ipbtcg27g6isrssieboj',
    // 'x-correlation-session-id': '3os0h88pnwd40ue2n5ka26',
    'x-correlation-session-id': user?.access_token.slice(-22),
    'x-tenant-id': '59dhhptudcn7hk1ogssvsb4cujvbcnh6o',
    'x-language': i18n.language,
  };
  headers = noCache ? { ...headers, 'Cache-Control': noCache } : { ...headers };
  headers = !imageAuthorizationHeader ? {
    ...headers,
    authorization: `${user?.token_type} ${user?.access_token}`,
  } : { ...headers };

  if (requestBaseUrl) {
    return axios({
      url: requestBaseUrl,
      method,
      headers,
      data,
      responseType,
      withCredentials: false,
      loader: loaderFlag || false,
    });
  }
  return axios({
    url,
    method,
    baseURL,
    headers,
    data,
    responseType,
    withCredentials: false,
    loader: loaderFlag || false,
  });
};

export const getService = async (args) => {
  try {
    const response = await getServiceCall(args);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const logErrorMessage = (error) => (dispatch) => {
  dispatch({
    type: LOG_ERROR,
    payLoad: error,
  });
};

export const loadingStatus = (data) => (dispatch) => {
  dispatch({
    type: IS_LOADING,
    payLoad: data,
  });
};

export const batchProcessingSuccess = (data) => (dispatch) => {
  dispatch({
    type: BATCH_JOB,
    payLoad: data,
  });
};

export const batchProcessing = (batcId) => async (dispatch) => {
  const response = await getService({
    method: 'get',
    url: `/batch-tasks/${batcId}`,
  });
  if (isStatusCodeSuccess(response?.status)) {
    dispatch(batchProcessingSuccess(response?.data));
  } else {
    dispatch(logErrorMessage(i18n.t('responseMessages.error.556')));
    // i18n.t('responseMessages.success.209'),
    // const gettingError = response?.data
    //   ? get(response, 'data?.error[0]?.message') || response.data
    //   : 'Service Error';
    // dispatch(logErrorMessage(gettingError));
  }
};

/**
 * Get items for channel and service
 */
export const updateMenuItemsForChannelService = (data) => async (dispatch) => {
  dispatch({
    type: CHANNEL_SERVICE_MENU_ITEM,
    payLoad: data,
  });
};

export const loadMenuItemsForChannelService = (
  { channel, service, storeId },
) => async (dispatch) => {
  const response = await getService({
    method: 'get',
    url: storeId ? `menu/items?channel=${channel}&service=${service}&store=${storeId}` : `menu/items?channel=${channel}&service=${service}`,
  });
  if (isStatusCodeSuccess(response?.status)) {
    dispatch(updateMenuItemsForChannelService(uniqBy(response?.data, 'id')));
  } else {
    dispatch(logErrorMessage(i18n.t('responseMessages.error.557')));
    // dispatch(logErrorMessage(response?.data || 'Service Error'));
  }
};

export const allSettledPromise = async (args) => {
  const actions = await args.map((each) => {
    const res = getServiceCall({
      requestBaseUrl: each.uploadURL,
      method: 'PUT',
      ContentType: each.fileType, // 'image/png', //
      data: each.fileObject,
      noCache: 'no-cache',
      imageAuthorizationHeader: true,
    });
    return res;
  });
  try {
    const getArray = [];
    const response = await Promise.allSettled(actions);
    response.forEach((element, index) => {
      getArray.push({ status: element.status, mainValue: args[index] });
    });
    // console.log('getArray=>', getArray);
    return getArray;
  } catch (error) {
    return error.responsegetArrayz;
  }
};
